import React from 'react'
import Checkmark from './misc/checkmark'
import PropTypes from 'prop-types'

const FeatureCard = ({ title }) => (
  <div className="feature-card">
    <div className="check">
      <div className="checkmark">
        <Checkmark />
      </div>
    </div>
    <h6>{title}</h6>
  </div>
)

FeatureCard.propTypes = {
  title: PropTypes.string
};

export default FeatureCard
