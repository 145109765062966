import React, { Component } from 'react'
import { Link } from 'gatsby'
import { employes } from 'images'
import { direct, hand, kosten, vragen, steps } from 'images/product/salaris'
import { HRHero, illuHr1, illuHr2, illuHr3 } from 'images/product/hr'
import * as images from 'images/home'
import Layout from '../../../layouts'
import Helmet from 'react-helmet'

import config from '../../../config'
const { appUrl, signupRoute } = config

const {
  avatars: { marieke, peter },
} = images

import Checkmark from 'components/misc/checkmark'
import FeatureCard from 'components/feature-card'
import ClientCard from 'components/client-card'
import ClientCardContainer from 'components/client-card-container'
import Steps from 'components/steps'

const RowOne = [
  'Arbeidscontracten',
  'Declaraties',
  'Digitaal ondertekenen',
  'Documentenbeheer',
  'Employee self-service',
  'Notities',
  'Werknemers uitnodigen',
  'Verlofaanvragen',
  'Vrije velden'

]

const RowTwo = [
  'Werknemers uitnodigen',
  'Verlofaanvragen',
  'Vrije velden',
  'Arbeidscontracten',
  'Declaraties',
  'Digitaal ondertekenen',
  'Documentenbeheer',
  'Employee self-service',
  'Notities'
]

const otherClientStories = [
  {
    name: 'Marieke',
    functionTitle: 'HR Manager, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description:
      'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: marieke,
    portraitImageSmall: marieke,
    quote: 'Het werkt als een trein',
  },
  {
    name: 'Peter',
    functionTitle: 'HR Manager, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description:
      'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: peter,
    portraitImageSmall: peter,
    quote: 'Het werkt als een trein',
  },
  {
    name: 'Johan',
    functionTitle: 'HR Manager, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description:
      'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x506',
    portraitImageSmall: 'https://via.placeholder.com/344x224',
    quote: 'Het werkt als een trein',
  },
]

const allPlans = [
  {
    problem: 'Een ingewikkeld salarispakket?',
    solution: 'Een gebruiksvriendelijke applicatie, die met jou meegroeit',
  },
  {
    problem: 'Veel correcties in salarisverwerking?',
    solution: 'Online toegankelijkheid, zodat je zaken zelf kunt invoeren',
  },
  {
    problem: 'Handmatig gegevens doorgeven via mail?',
    solution:
      'De mogelijkheid om zelf gegevens te controleren en aan te passen',
  },
  {
    problem: 'Veel salariskennis nodig?',
    solution:
      'Automatische herinneringen, zodat het goed in jullie workflow te integreren is',
  },
]

class HR extends Component {
  constructor(props) {
    super(props)

    this.scrolling = this.scrolling.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrolling)
    this.scrolling()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrolling)
  }

  scrolling() {
    if (window.innerWidth > 1280) {
      const steps = this.refs.steps
      const stepSizes = steps.getBoundingClientRect()
      const windowHeight = window.innerHeight

      if (windowHeight - stepSizes.height >= stepSizes.top) {
        steps.classList.add('start')
      }

      const slider = this.refs.slider
      const sliderTop = Math.round(slider.getBoundingClientRect().top)

      const firstStep = this.refs.stepOne
      const firstStepTop = Math.round(firstStep.getBoundingClientRect().top)

      const secondStep = this.refs.stepTwo
      const secondStepTop = Math.round(secondStep.getBoundingClientRect().top)

      const finalStep = this.refs.stepThree
      const { top, height } = finalStep.getBoundingClientRect()
      const full = Math.round(top)

      if (secondStepTop <= 540 && secondStepTop > 0) {
        this.refs.illuOne.classList.remove('show')
        this.refs.illuTwo.classList.add('show')
        this.refs.illuThree.classList.remove('show')
      } else if (secondStepTop <= 0) {
        this.refs.illuTwo.classList.remove('show')
        this.refs.illuThree.classList.add('show')
      } else {
        this.refs.illuThree.classList.add('show')
        this.refs.illuTwo.classList.remove('show')
      }

      if (full <= 240) {
        this.refs.box.style.position = 'absolute'
        this.refs.box.style.top = 'auto'
        this.refs.box.style.bottom = '0'
      } else if (sliderTop <= 28) {
        this.refs.box.style.position = 'fixed'
        this.refs.box.style.top = '88px'
        this.refs.box.style.bottom = 'auto'
      } else {
        this.refs.box.style.position = 'absolute'
        this.refs.box.style.top = '60px'
        this.refs.box.style.bottom = 'auto'
      }
    }
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="hr">
          <Helmet>
            <title>
              HR-pakket | HR-software | HR en salaris gecombineerd | Employes
            </title>
            <meta
              name="description"
              content="Employes is hét online HR-pakket. De HR-software biedt een centrale plek om zaken rondom HR en salaris bij te houden. Probeer gratis!"
            />
            <meta
              itemprop="name"
              content="HR-pakket | HR-software | HR en salaris gecombineerd | Employes"
            />
            <meta
              itemprop="description"
              content="Employes is hét online HR-pakket. De HR-software biedt een centrale plek om zaken rondom HR en salaris bij te houden. Probeer gratis!"
            />
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="header padding-xxl animated fadeInPage">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-8">
                  <h1>
                    Salaris & HR-pakket. <br />
                    Alles op één plek.
                  </h1>
                  <p className="streamer wide large margin-m-bottom">
                    Met Employes regel je alle HR-zaken voor je bedrijf op een moderne en overzichtelijke manier.
                    Stroomlijn het beheer van contracten, verlof- en verzuimregistraties, declaraties en meer.
                  </p>
                  <a
                    className="btn primary lg margin-xs-bottom"
                    href={appUrl + signupRoute}
                    rel="nofollow"
                  >
                    Probeer nu gratis
                  </a>
                  <br />
                  <p className="small sky-dark hide-mobile">
                    Geheel vrijblijvend | Geen salariskennis nodig | Eenvoudig starten
                  </p>
                </div>

                <div className="hero-illustration">
                  <img src={HRHero} alt="employes illustratie" />
                </div>
              </div>
            </div>
          </header>

          <section className="slider" ref="slider">
            <div className="backdrop small"></div>
            <div className="slider-container small">
              <div className="feature-content step" id="step-1" ref="stepOne">
                <div className="container-md padding-xxxl">
                  <div className="grid yg">
                    <div className="col-6 feature-illustration">
                      <div>
                        <img src={illuHr2} alt="Illustration" ref="illuOne" />
                      </div>
                    </div>
                    <div className="col-6 right">
                      <p className="eyebrow">Veilig en toegankelijk</p>
                      <h2>Complete online personeelsadministratie</h2>
                      <p>
                        In een beveiligde online omgeving kun je de gegevens van
                        je werknemers opslaan, denk bijvoorbeeld aan het
                        arbeidscontract, een ID-kaart en de
                        loonbelastingverklaring. Vanuit Employes heb je ook de
                        mogelijkheid om documenten met je werknemers te delen.
                        En niet onbelangrijk: onze HR-software voldoet aan de
                        AVG.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature-content step" id="step-2" ref="stepTwo">
                <div className="container-md padding-xxxl-bottom">
                  <div className="grid yg">
                    <div className="col-6 feature-illustration">
                      <div>
                        <img src={illuHr2} alt="Illustration" ref="illuTwo" />
                      </div>
                    </div>
                    <div className="col-6 right">
                      <p className="eyebrow">Efficiënte HR-software</p>
                      <h2>Automatisch arbeidscontracten aanmaken</h2>
                      <p>
                        Na het invoeren van de gegevens van een werknemer, kun
                        je met één druk op de knop een arbeidscontract aanmaken.
                        Je kunt gebruik maken van de templates van Employes die
                        juridisch gecontroleerd zijn. Je kunt natuurlijk ook
                        zelf een template uploaden. Als je juridisch advies wilt
                        over de contracten, kun je ook bij ons terecht.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature-content step" id="step-3" ref="stepThree">
                <div className="container-md padding-xxxl-bottom">
                  <div className="grid yg">
                    <div className="col-6 feature-illustration">
                      <div>
                        <img src={illuHr3} alt="Illustration" ref="illuThree" />
                      </div>
                    </div>
                    <div className="col-6 right">
                      <p className="eyebrow">
                        Gemak voor werknemer en werkgever
                      </p>
                      <h2>Online verlof en verzuimregistratie</h2>
                      <p className="margin-xs-bottom">
                        Via het portal van Employes kan de werknemer online zijn
                        verlofaanvraag indienen. Na goedkeuring van de aanvraag
                        wordt dit direct doorgevoerd in de salarisadministratie.
                        In Employes is de verlofadministratie namelijk gekoppeld
                        aan de salarisadministratie.
                      </p>
                      <Link to="/product/verlof" className="link blue">
                        Bekijk Verlof
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box" ref="box">
                <div className="container-md">
                  <div className="grid yg">
                    <div className="col-6">
                      <div className="image-container">
                        <img src={illuHr1} alt="Illustration" ref="illuOne" />
                        <img src={illuHr2} alt="Illustration" ref="illuTwo" />
                        <img src={illuHr3} alt="Illustration" ref="illuThree" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="options">
            <div className="overview">
              <div className="container-md">
                <div className="grid yg center text-center">
                  <div className="col-6">
                    <h4 className="subtitle padding-xl">
                      Moderne HR-software.
                      <br /> Alles online en op één plek.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="features-overview margin-xl-bottom">
                <div className="features-row">
                  {RowOne.map((title, idx) => (
                    <FeatureCard key={idx} title={title} />
                  ))}
                </div>
                <div className="features-row">
                  {RowTwo.map((title, idx) => (
                    <FeatureCard key={idx} title={title} />
                  ))}
                </div>
              </div>
              <div className="container-md margin-l-bottom">
                <div className="grid yg center text-center">
                  <div className="col-6">
                    <Link
                      className="btn tertiary lg"
                      to="/product/mogelijkheden/"
                    >
                      Bekijk alle mogelijkheden
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="features padding-xxl">
            <div className="container-md">
              <div className="grid center text-center">
                <div className="col-12">
                  <p className="eyebrow">Onderscheiden</p>
                  <h2>Voordelen van Employes</h2>
                  <p className="streamer wide center margin-xl-bottom">-</p>
                </div>

                <div className="col-3 feature">
                  <div className="feature-icon">
                    <img src={hand} className="hand" alt="hand" />
                  </div>
                  <h5>HR en salaris op 1 plek</h5>
                  <p>
                    Via Employes regel je de HR- en salarisadministratie op
                    dezelfde plek. Deze samenhang zorgt ervoor dat je
                    tijdsefficiënt met personeelsprocessen om kunt gaan.
                    Opgenomen vrije dagen worden bijvoorbeeld automatisch
                    bijgewerkt op de loonstrookjes.
                  </p>
                </div>

                <div className="col-3 feature">
                  <div className="feature-icon">
                    <img src={direct} className="direct" alt="direct" />
                  </div>
                  <h5>Toegang voor werknemers</h5>
                  <p>
                    Employes is een HR-pakket dat in een beveiligde online
                    omgeving toegankelijk is. Werknemers hebben toegang tot een mobiele app of een online omgeving. Daar kunnen zij zelf hun salarisstroken en
                    jaaropgaven inzien en downloaden. Dat scheelt jou weer
                    uitzoekwerk.
                  </p>
                </div>

                <div className="col-3 feature">
                  <div className="feature-icon">
                    <img src={kosten} className="kosten" alt="kosten" />
                  </div>
                  <h5>Kosten HR-software</h5>
                  <p>
                    Voor het gebruik van Employes betaal je vaste kosten en
                    kosten per werknemer. Je hebt de keuze uit een aantal
                    pakketten, waarvan vooraf duidelijk is gedefinieerd wat bij
                    de prijs inbegrepen zit. Je kunt er dus op rekenen dat je
                    nooit vervelende verrassingen achteraf krijgt.
                  </p>
                </div>

                <div className="col-3 feature">
                  <div className="feature-icon">
                    <img src={vragen} className="vragen" alt="vragen" />
                  </div>
                  <h5>Vragen snel beantwoord</h5>
                  <p>
                    De mensen achter Employes staan je graag persoonlijk te
                    woord wanneer je vragen of opmerkingen hebt. Je kunt ons
                    bereiken via de online chat, telefoon of e-mail. Gaat je
                    vraag over het gebruik van de applicatie? Misschien staat
                    het antwoord al bij onze{' '}
                    <Link to="/klantenservice/">veelgestelde vragen.</Link>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="benefits padding-xl">
            <div className="container-md">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <div className="plan-overview">
                    <div className="title">
                      <img src={employes} alt="Employes logo" />
                      <h5>&nbsp;staat voor:</h5>
                    </div>

                    <div className="plans">
                      <ul>
                        {allPlans.map((plan, idx) => (
                          <li className="plan lg" key={idx}>
                            <div className="content">
                              <Checkmark small={true} />
                              <h6>{plan.solution}</h6>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <p className="eyebrow">Neem het heft in eigen hand</p>
                  <h2>Grip op de personeelsadministratie</h2>
                  <p>
                    Je kunt je online in ongeveer 6 minuten aanmelden voor
                    Employes. De applicatie kan je in een demo-omgeving
                    vrijblijvend uitproberen. Het gebruiksvriendelijke design is
                    specifiek ingericht voor het MKB. Je wordt stap voor stap
                    door het proces meegenomen zodat jij snel je administratie
                    op orde hebt.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="change padding-xxl" ref="steps">
            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <p className="eyebrow">Overstappen</p>
                  <h2>Combinatie salaris, verlof & HR-pakket</h2>
                  <p className="margin-s-bottom">
                    Employes biedt een online omgeving waarin je 100% online
                    salarisadministratie kunt voeren, in combinatie met
                    HR-processen. Verlof, ziekte en verzuim werken allemaal door
                    op de salarisverwerking. In Employes wordt dit automatisch
                    verrekend. Je hoeft een nieuwe werknemer maar eenmalig toe
                    te voegen, omdat je op een centrale plek werkt. Je gebruikt
                    hetzelfde dossier voor alle processen. Zo heb je de
                    zekerheid dat alles op het gebied van HR en salaris goed
                    geregeld is.{' '}
                  </p>
                  <Link
                    to="/product/salarisadministratie/"
                    className="link blue"
                  >
                    Meer informatie over salaris
                  </Link>
                </div>
                <div className="col-6">
                  <img src={illuHr2} alt="Illustration" ref="alles1plek" />
                </div>
              </div>
            </div>
          </section>

{/*
            <ClientCardContainer
              stories={otherClientStories}
            />
*/}
        </main>
      </Layout>
    )
  }
}

export default HR
