import React from 'react'
import ClientCard from './client-card'

const ClientCardContainer = ({stories}) => (
  <section className="preceded client-section padding-xxl">
    <div className="container-md">
      <div className="client-cards">
        <div className="grid yg">
          <ClientCard className="col-8 hide-tablet no-margin" person={stories[0]} isSmall={false}/>
          <ClientCard className="col-4 hide-mobile hide-tablet" person={stories[1]} isSmall={true}/>
          <ClientCard className="col-6 extra-small no-pad show-mobile no-margin" person={stories[1]} isSmall={true}/>
        </div>
      </div>
    </div>
  </section>
)

export default ClientCardContainer
